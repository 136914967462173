import { graphql, Link, PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { Card } from '../components/Sections/Card'
import { CoursesGrid } from '../components/CoursesGrid'
import { filterCurrentCourses } from '../utils/courses'
import ContactFormDialog from '../components/ContactFormDialog'
import { useState } from 'react'

const CursosPage = ({
  data: {
    directus: { page, courses },
  },
}: PageProps<Queries.CursosPageQuery>) => {
  if (!page) {
    return null
  }

  const currentCourses = filterCurrentCourses(courses)

  const [contactIsOpen, setContactIsOpen] = useState(false)

  return (
    <Layout pageHeader={page.page_header}>
      {page.intro_card && <Card className="my-16" {...page.intro_card} />}
      <CoursesGrid
        heading={page.current_courses_heading}
        description={page.current_courses_description}
        courses={currentCourses}
      />
      {page.open_courses_card && (
        <Card className="my-16" {...page.open_courses_card} />
      )}
      {page.private_courses_card && (
        <Card className="my-16" {...page.private_courses_card}>
          <ContactFormDialog
            show={contactIsOpen}
            toggle={setContactIsOpen}
            header={
              <>
                <div className="mb-4 text-2xl font-bold text-brand-dark-blue">
                  {page.private_courses_card.title}
                </div>
                <h3 className="mb-2.5 text-xl font-semibold">
                  {page.private_courses_card.button_link?.label}
                </h3>
              </>
            }
            form={
              <>
                <input
                  type="hidden"
                  name="subjectPrefix"
                  value={`${page.private_courses_card.title}`}
                />
              </>
            }
          />
          <Link
            className="Button --light"
            to="/contacto"
            onClick={(e) => {
              e.preventDefault()
              setContactIsOpen(true)
            }}
          >
            {page.private_courses_card.button_link?.label}
          </Link>
        </Card>
      )}
    </Layout>
  )
}

export default CursosPage

export { PageHead as Head } from '../components/PageHead'

export const query = graphql`
  query CursosPage {
    directus {
      page: page_cursos {
        meta_description
        page_header {
          ...PageHeader
        }
        intro_card {
          ...CardComponent
        }
        current_courses_heading
        current_courses_description
        open_courses_card {
          ...CardComponent
        }
        private_courses_card {
          ...CardComponent
        }
      }
      courses(filter: { status: { _eq: "published" } }, sort: "start_date") {
        ...CourseGridCard
      }
    }
  }
`
